import React, { ReactNode } from "react"
import { graphql } from "gatsby"
import { Content } from "components/content/Content"
import { ImageBuilder } from "_types/AssetsImage"
import { Image } from "components/image/Image"
import { RichTextContent } from "_types/RichText"
import "./blog-post.module.scss"

interface BlogPostProps {
  heading?: string
  publishedDate?: string
  content: RichTextContent
  featuredImage: ImageBuilder
  children?: ReactNode
}

export const BlogPost = ({
  heading,
  content,
  featuredImage,
  publishedDate,
  children,
}: BlogPostProps) => {
  return (
    <section className="fr-container fr-container--large fr-blog-post__container">
      <article className="fr-container u-content-block-padding fr-blog-post">
        {heading && <h1 className="fr-blog-post__heading">{heading}</h1>}

        {content?.images?.length === 0 && featuredImage && (
          <div className={`fr-blog-post__image`}>
            <Image
              image={featuredImage}
              alt={featuredImage.description}
              width={featuredImage.width}
            />
          </div>
        )}

        {content?.value.toString() !== "<p><br></p>" && (
          <div className="fr-blog-post__content">
            {publishedDate && (
              <h3 className="fr-blog-post__published-date">{publishedDate}</h3>
            )}
            <Content content={content} />
          </div>
        )}

        {children}
      </article>
    </section>
  )
}

export const query = graphql`
  fragment BlogPostFragment on kontent_item_blog_post_elements {
    heading {
      value
    }
    category {
      value {
        ... on kontent_item_category {
          elements {
            category_name {
              value
            }
            slug {
              value
            }
          }
        }
      }
    }
    blog_post_content {
      ...RichTextContentFragment
    }
    featured_image {
      value {
        ...KenticoAssetElementFragment
      }
    }
  }
`
