import React from "react"
import { useWindowDimensions } from "hooks/useWindowDimensions"
import { GoTriangleLeft, GoTriangleRight } from "react-icons/go"
import { Button } from "components/button"
import { BlogPostEdge } from "../BlogBanner/blog-banner-types"
import "./internal-pagination.module.scss"
import { useLayout } from "contexts/components/LayoutContext"

interface InternalPaginationProps {
  prev?: BlogPostEdge["node"]
  next?: BlogPostEdge["node"]
  parentSlug: string
  isPrevNextEnabled?: boolean
}

export const InternalPagination = ({
  prev,
  next,
  parentSlug,
  isPrevNextEnabled = false,
}: InternalPaginationProps) => {
  const { linkedPagesUrlPathCodex } = useLayout()
  const { size } = useWindowDimensions()

  const formatParentSlugText = (slug: string) => {
    if (slug.endsWith("s")) {
      return slug
    }
    return `${slug}s`
  }

  const withoutNextAndPreviousClassName = isPrevNextEnabled
    ? ""
    : "fr-internal-pagination--no-controller-links"

  const previousSlug = prev?.elements.slug.value || ""
  const nextSlug = next?.elements.slug.value || ""

  return (
    <section className="alternate-background-color fr-internal-pagination__container">
      <div
        className={`fr-internal-pagination ${withoutNextAndPreviousClassName}`}
      >
        {isPrevNextEnabled && (
          <Button
            variant="ghost-page-link"
            to={previousSlug && `/${linkedPagesUrlPathCodex[previousSlug]}`}
            extraClassNames={`fr-internal-pagination__controller-link ${
              !previousSlug ? "fr-internal-pagination__controller-link" : ""
            }`}
            disabled={!previousSlug}
          >
            <div className={`fr-internal-pagination__link-info`}>
              <span className={`fr-internal-pagination__previous-btn`}>
                <GoTriangleLeft size={30} />
                Prev{size >= 1024 && "ious Blog"}
              </span>
            </div>
          </Button>
        )}
        <Button
          variant="outline-primary"
          to={`/${parentSlug}`}
          extraClassNames="fr-internal-pagination__view-all-btn"
        >
          {size >= 1024 && "See "}More {formatParentSlugText(parentSlug)}
        </Button>
        {isPrevNextEnabled && (
          <Button
            variant="ghost-page-link"
            to={nextSlug && `/${linkedPagesUrlPathCodex[nextSlug]}`}
            extraClassNames={`fr-internal-pagination__controller-link ${
              !next ? "fr-internal-pagination__controller-link" : ""
            }`}
            disabled={!nextSlug}
          >
            <div className={`fr-internal-pagination__link-info`}>
              <span className={`fr-internal-pagination__next-btn`}>
                <span className="fr-internal-pagination__next-btn--label">
                  Next{size >= 1024 && " Blog"}
                </span>
                <GoTriangleRight size={30} />
              </span>
            </div>
          </Button>
        )}
      </div>
    </section>
  )
}
